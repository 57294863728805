<template lang='pug'>
games-filter
.events-wrap(v-if="events.length>0")
	.event(v-for="event in events")
		.event__date {{ event.date }}
		.event__city {{ event.n }}

		.event__team-one
			.event__team-score(v-if='event.score') {{ event.teams[1].score }}
			.event__team-name {{ event.teams[1].name }}
			.event__team-city(v-if='event.teams[1].city') {{ event.teams[1].city }}
		.event__team-two
			.event__team-score(v-if='event.score') {{ event.teams[2].score }}
			.event__team-name {{ event.teams[2].name }}
			.event__team-city(v-if='event.teams[2].city') {{ event.teams[2].city }}
		.event__score-detail(v-if='event.score') {{event.score_detail.join('&nbsp;&nbsp;')}}
</template>

<script>
import gamesFilter from '@/components/gamesFilter'
export default {
	name:'games',
	components: {gamesFilter},
	props:['events']
}
</script>

<style lang='scss'>
.event{
	&{
		margin-bottom: 20px;
		background-color: #fff;
		padding: 15px 15px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 15px 10px
	}
	&__date,
	&__city{
		color:#999;
		font-size:13px;
	}

	&__city{
		text-align: right;
	}

	&__team-one,
	&__team-two{
		text-align: center;
	}

	&__team-name{
		font-weight: 500;
		font-size: 15px;
	}

	&__team-city{
		font-size: 13px;
		color:#999;
		margin-top: 5px;
	}


	&__team-score{
		font-weight: 900;
		color:#aaa;
		font-size: 25px;
		margin-bottom: 5px;
	}


	&__score-detail{
		grid-column: auto /span 2;
		text-align: center;
		font-size:14px;
		color:#666;
	}
}
</style>