<template lang='pug'>
h1 Результаты
lastGames
</template>

<script>
import lastGames from '@/components/lastGames'
export default {
	name: 'Results',
	components: {lastGames}
}
</script>