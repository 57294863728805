<template lang='pug'>
.filter
	select(@change='selectFilter')
		option(value='0') Все команды
		option(v-for="team,key in teams",:value="key", :selected="team.active") {{team.name}}
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'
export default {
	name:'gamesFilter',
	setup(){
		const store=useStore()
		const selectFilter=function(e){
			store.commit('setActiveTeam',e.target.value)
		}
		return{
			teams:computed(()=>store.getters.teams),selectFilter
		}
	}
}
</script>

<style lang='scss'>
.filter{
	margin-bottom: 20px;
}

select{
	width:100%;
	padding:10px;
}
</style>